@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  background-color: #fff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html.dark, body.dark {
  background-color: rgb(17 24 39); /* bg-gray-900 */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.clue {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.05em;
}


.text-red-orange {
  color: #fb5607;
}

.text-real-red {
  color: #ff1313;
}

.text-hot-pink {
  color: #ff006e;
}

.bg-real-blue {
  background-color: #10a9ff;
}

.bg-real-green {
  background-color: #12d263;
}

.bg-gradient {
  background: linear-gradient(
    91.31deg,
    #ffbe0b -6.76%,
    #fb5607 14.4%,
    #ff006e 35.98%,
    #8338ec 57.73%,
    #3a86ff 86.24%,
    #3aa0ff 106.51%
  );
}

.image-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 66.5%
  );
}

.login-button {
  background: rgba(16, 169, 255, 0.2);
  color: #10a9ff;
}

.login-button:hover {
  background: rgba(16, 169, 255, 0.3);
}

.google-btn {
  width: 80%;
  min-width: 184px;
  max-width: 184px;
  height: 42px;
  background-color: #fcfcfc;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  cursor: hand;
  align-self: center;
  user-select: none;
  transition: all 400ms ease 0s;
  display: flex;
}
.google-btn .google-icon-wrapper {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  user-select: none;
}
.google-btn .google-icon-svg {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
  user-select: none;
}
.google-btn .btn-text {
  float: right;
  margin: 11px 14px 40px 40px;
  color: #757575;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: Roboto;
  user-select: none;
}
.google-btn:hover {
  box-shadow: 0 3px 8px rgba(117, 117, 117, 0.5);
  user-select: none;
}
.google-btn:active {
  box-shadow: 0 1px 1px #757575;
  background: #f8f8f8;
  color: #fff;
  user-select: none;
}

.border-thin {
  border-width: 0.5px;
}

div {
  letter-spacing: -0.05em;
}

button {
  letter-spacing: -0.05em;
}

.text-sky-blue {
  color: #10a9ff;
}

.history-thumbnail {
  padding: 1px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.suggestions-button {
  color: #3aa0ff;
  background-color: #e7f6ff;
}

.bg-neon-green {
  background-color: #12d263;
}

.blue-text {
  color: #3aa0ff;
}

.w-7-5 {
  width: 1.875rem;
}

.cream-background {
  background-color: #fdf6ea;
}

.image-wrapper {
  box-sizing: content-box;
}

.image-wrapper > span {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bg-hot-pink {
  background-color: #ff006e;
}

.hide-external-links .external-link {
  display: none;
}


/* Used for the "I give up" animation */
@keyframes popAndJiggle {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1) rotate(-5deg);
  }
  85% {
    transform: scale(1) rotate(5deg);
  }
  90% {
    transform: scale(1) rotate(-5deg);
  }
  95% {
    transform: scale(1) rotate(5deg);
  }  
  100% {
    transform: scale(1) rotate(0deg);
  }    
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    scale: 1;
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    transform: translateY(-10px);
    scale: 1.05;
    opacity: 1;
  }
  60% {
    transform: translateY(-5px);
    scale: 0.95;
    opacity: 1;
  }
}


@keyframes colorChange {
  0%, 100% {
    border-color: #ffca28;
    border-width: 2px;
  }
  25% {
    border-color: #66bb6a;
    border-width: 2px;
  }
  50% {
    border-color: #42a5f5;
    border-width: 2px;
  }
  75% {
    border-color: #ab47bc;
    border-width: 2px;
  }
}

.solved-animation {
  animation: bounce 1s 1 linear, colorChange 1s 1 linear;
}


.hide-scrollbar {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

/* For Chrome, Edge, and Safari */
.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.hide-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}



/*
AD CONTAINER CSS
*/
.ad-container {
  align-items: end;
  justify-content: center;
  width: 100%;
  z-index: 2;
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  clip: rect(auto, auto, auto, auto);
  clip-path: inset(0 0 0 0);
  contain: paint;
}
.ad-container .adthrive-ad {
  margin-top: 0;
  margin-bottom: 0.25em;
}

#sidebar.ad-container {
  left: calc(50vw + 300px);
  top: 0px;
  max-width: 300px;
}

/*
headlessui-portal-root override for z-index
*/
#headlessui-portal-root {
  z-index: 2147483647;
  position: absolute; 
}

html, body {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}
#__next {
  /* overflow: hidden; */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  height: 100dvh;
  width: 100%;
  position: relative;
  z-index: 2147483646;
}